<template>
<div class="technique-view ">
    <div class="flex justify-center">
        <div class="technique-top-view mw-1920 pt-200">
            <div class="technique-top-filter-text">
                <div class="technique-top-text">
                    <h1 class="top-h1" data-aos="fade-down">Список товарів та техніки</h1>
                    <p class="top-p mt-20px" data-aos="fade-down">Лізинг вигідно і дешево</p>
                </div>
                <div class="technique-filter" data-aos="fade-up">
                    <div class="technique-filter-items">
                        <div class="technique-filter-item ">
                            <button class="filter-button" @click="filterOpen=!filterOpen">Фільтри <img src="@/assets/icons/chewron-down.svg" alt=""></button>
                        </div>
                        <div class="technique-filter-item flex items-center ">
                            <button class="filter-apply"> {{$t('filter')}} </button>
                            <button class="filter-clear"> {{$t('reset')}}  </button>
                        </div>
                    </div>
                    <el-collapse-transition>
                        <div class="technique-filter-inputs" v-show="filterOpen">
                            <div class="filter-inputs-item">
                                <h1>Тип:</h1>
                                <div class="filter-item-input">
                                    <CheckBlack />
                                    <p>Легковий автотранспорт</p>
                                </div>
                                <div class="filter-item-input">
                                    <CheckBlack />
                                    <p>Сільськогосподарська техніка</p>
                                </div>
                                <div class="filter-item-input">
                                    <CheckBlack />
                                    <p>Вантажний автотранспорт</p>
                                </div>
                                <div class="filter-item-input">
                                    <CheckBlack />
                                    <p>Будівельна техніка</p>
                                </div>
                            </div>
                            <div class="filter-inputs-item">
                                <h1>Вартість, грн:</h1>
                                <div class="filter-view">
                                    <p class="filter-view-title">Від:</p>
                                    <div class="filter-view-text">
                                        <p>100 000 грн</p>
                                    </div>

                                </div>
                                <div class="filter-view">
                                    <p class="filter-view-title">До:</p>
                                    <div class="filter-view-text">
                                        <p>500 000 грн</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </el-collapse-transition>
                </div>

            </div>

            <div class="technique-top-bg">
                <img src="@/assets/img/stockbg.svg" alt="">
            </div>
        </div>
    </div>
    <div class="flex justify-center">
        <div class="technique mw-1920   w-full">
            <div class="technique-item" v-for="i in 6" :key="i" data-aos="fade-up">
                <div class="technique-card">
                    <div class="technique-image">
                        <img src="@/assets/img/combayn.png" alt="">
                    </div>
                    <div class="technique-text">
                        <h1>Комбайн AX-32233</h1>
                        <h2>Від 40 000 грн / міс</h2>
                        <div class="technique-text-info">
                            <p>Вартість, грн</p>
                            <p style="opacity:0.8;">3 650 000</p>
                        </div>
                        <div class="technique-text-info">
                            <p>Виробник</p>
                            <p style="opacity:0.8;">RIA</p>
                        </div>
                        <div class="technique-text-info">
                            <p>Країна виробника</p>
                            <p style="opacity:0.8;">США</p>
                        </div>

                    </div>
                    <div class="technique-button">
                        <router-link tag="a" to="/leasing/technique/1" class="technique-button-black">Детальніше </router-link>
                        <router-link tag="a" to="/leasing/technique/get/1"   class="technique-button-blue">Подати заявку</router-link>
                    </div>
                </div>
            </div>
            <div class="w-full flex justify-center mt-40px" data-aos="fade-up">
                <button class="button gray flex items-center" @click="a=a+6">Більше <img class="ml-10" src="@/assets/icons/down.svg" alt=""> </button>
            </div>
            <div class="technique-pogination" data-aos="fade-up">
                <el-pagination :page-size="1" :pager-count="4" layout="prev, pager, next" :total="8">
                </el-pagination>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import CheckBlack from '../../components/check-black.vue'
export default {
    name: 'technique',
    components: {
        CheckBlack
    },
    data() {
        return {
            filterOpen: false
        }
    }

}
</script>

<style lang="scss">
.technique-view{
    background: var(--white-bg);
}
.technique-top-view {
    width: 100%;
    text-align: center;
    background: var(--bg-top);
    padding: 150px 120px;
    position: relative;
}

.stock-mover {
    transform: translateY(-100px);
}

.technique-top-bg {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    z-index: 0;

    img {
        width: 30%;
    }
}

.technique-top-filter-text {
    width: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-between;

    .technique-top-text {
        h1 {
            text-align: left;
        }

        p {
            text-align: left;
        }
    }

    .technique-filter {
        box-shadow: 0px 6px 16px -8px rgba(0, 72, 153, 0.12), 0px 9px 28px rgba(0, 72, 153, 0.05), 0px 12px 48px 16px rgba(0, 72, 153, 0.03);
        border-radius: 8px;
        background: var(--white-bg);
        max-width: 550px;
        width: 100%;
        max-height: 90px;
        position: relative;
    }

    .filter-button {
        border: none;
        outline: none;
        cursor: pointer;
        background: none;
        font-family: Stem-Medium;
        font-size: 20px;
        line-height: 42px;
        font-feature-settings: 'tnum'on, 'lnum'on;
        color: var(--other-black);
        display: flex;
        align-items: center;
        margin: 0;

        img {
            margin-top: 2px;
            margin-left: 10px;
        }
    }

    .technique-filter-items {
        background: var(--white-bg);
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 8px;
        position: relative;
        z-index: 1;
        padding: 20px;
        height: 100%;
    }

    .technique-filter-item {
        background: var(--white-bg);
        display: flex;
        align-items: center;

    }

    .filter-apply {
        padding: 10px 20px;
        border: none;
        outline: none;
        cursor: pointer;
        background: #00B5EC;
        border-radius: 4px;
        margin-right: 20px;
        color: var(--white-color);
        font-family: Stem-Medium;
        font-size: 1rem;
        line-height: 1.125rem;

    }

    .filter-clear {
        font-family: Stem-Medium;
        font-size: 1rem;
        line-height: 1.125rem;
        color: #909090;
        border: none;
        outline: none;
        cursor: pointer;
        background: none;

    }

    .technique-filter-inputs {
        position: absolute;
        transition: all 0.3s ease-in;
        left: 0;
        top: 90%;
        width: 100%;
        background: var(--white-bg);
        border-radius: 0px 8px 8px 0px;
        z-index: 0;
        display: flex;
        box-shadow: 0px 6px 16px -8px rgba(0, 72, 153, 0.12), 0px 9px 28px rgba(0, 72, 153, 0.05), 0px 12px 48px 16px rgba(0, 72, 153, 0.03);

        .filter-inputs-item {
            padding: 30px 40px 20px 40px;
        }

        h1 {
            text-align: left;
            font-family: Stem-Medium;
            font-size: 1rem;
            line-height: 1.125rem;
            color: var(--other-black);
            margin-bottom: 10px;
        }

        .filter-item-input {
            padding: 10px 0px;
            display: flex;
            align-items: center;

            p {
                margin-left: 20px;
                font-family: Stem-Regular;
                font-size: 0.875rem;
                line-height: 1rem;
                text-align: left;
                color: var(--white-black);
            }
        }

        .filter-view-title {
            font-family: Stem-Regular;
            font-size: 0.875rem;
            line-height: 1rem;
            color: var(--white-black);
            text-align: left;
        }

        .filter-view {
            padding: 10px 0px;
        }

        .filter-view-text {
            margin-left: 10px;
            margin-top: 10px;
            border-bottom: 1px solid #DDDFE2;
            padding-bottom: 7px;

            p {
                font-family: Stem-Regular;
                font-size: 0.875rem;
                line-height: 1rem;
                color: var(--white-black);
            }

        }
    }
}

.technique {
    padding: 0px 90px;
    display: flex;
    flex-wrap: wrap;
    transform: translateY(-100px);

    .technique-item {
        padding: 30px;
        max-width: 100%;
    }

    .technique-card {
        width: 370px;
        max-width: 100%;
        padding: 10px 30px;
        background: var(--white-bg);
        box-shadow: 0px 6px 16px -8px rgba(0, 72, 153, 0.12), 0px 9px 28px rgba(0, 72, 153, 0.05), 0px 12px 48px 16px rgba(0, 72, 153, 0.03);
        border-radius: 10px;

        .technique-image {
            padding: 20px 0px;
            height: 170px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                max-height: 100%;
            }
        }

        .technique-text {
            h1 {
                font-family: Stem-Medium;
                font-size: 1.125rem;
                line-height: 1.25rem;
                color: var(--color-blue);
                margin-top: 30px;
            }

            h2 {
                font-family: Stem-Medium;
                font-size: 0.875rem;
                line-height: 1rem;
                margin: 10px 0px;
                color: var(--other-black);
            }

            .technique-text-info {
                display: flex;
                align-items: center;
                padding: 10px 0px;
                justify-content: space-between;

                p {
                    font-family: Stem-Regular;
                    font-size: 0.875rem;
                    line-height: 1rem;
                    color: var(--other-black);
                }

            }

        }

        .technique-button {
            padding: 20px 0px;
            margin-top: 10px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            a{
                text-decoration: unset;
            }

            .technique-button-black {
                padding: 10px 20px;
                border: 1px solid #454851;
                background: var(--black);
                text-align: center;
                border-radius: 4px;
                color: var(--white-color);
                font-family: Stem-Medium;
                font-size: 0.875rem;
                line-height: 1rem; 
                width: 48%;
                transition: all 0.2s ease-in;
                &:hover{
                    background: var(--white-bg);
                    color: var(--other-black);
                }
            }

            .technique-button-blue {
                padding: 10px 20px;
                border: 1px solid #00B5EC;
                background: #00B5EC;
                border-radius: 4px;
                color: var(--white-color);
                font-family: Stem-Medium;
                font-size: 0.875rem;
                line-height: 1rem; 
                text-align: center;
                width: 48%;
                transition: all 0.2s ease-in;
                &:hover{
                    background: var(--white-bg);
                    color: var(--color-blue);
                }
            }
        }
    }
}
.technique-pogination{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 40px;
}

@media screen and(max-width:1100px) {
    .technique-top-view {
        padding: 100px 20px;
    }

    .technique {
        padding: 0px 20px;
    }

    .technique-top-filter-text {
        flex-direction: column;
        align-items: center;

        .technique-top-text {
            width: 100%;

            h1 {
                text-align: center;
            }

            p {
                text-align: center;
            }
        }

        .technique-filter {
            margin-top: 50px;
        }

    }
}

@media screen and(max-width:950px) {
    .technique-top-view {
        padding: 100px 20px;
    }

}

@media screen and(max-width:768px) {
    .technique-top-view {
        padding: 60px 20px 100px 20px;

        .tal {
            text-align: center !important;
        }
    }

    .technique-top-bg {
        display: none;
    }

    .technique-top-filter-text {
        .filter-button {
            font-size: 1rem;
            line-height: 1.125rem;
        }

        .technique-filter-item {
            background: var(--white-bg);
            display: flex;
            align-items: center;

        }

        .filter-apply {
            padding: 10px 15px;
            font-size: 0.875rem;
            line-height: 1rem;
            margin-right: 10px;

        }

        .filter-clear {
            font-size: 0.875rem;
            line-height: 1rem;

        }

        .technique-filter-inputs {
            flex-wrap: wrap;

            .filter-inputs-item {
                padding: 20px 40px 30px 40px;
            }

            h1 {
                text-align: left;
                font-family: Stem-Medium;
                font-size: 1rem;
                line-height: 1.125rem;
                color: var(--other-black);
                margin-bottom: 10px;
            }

            .filter-item-input {
                padding: 10px 0px;
                display: flex;
                align-items: center;

                p {
                    margin-left: 20px;
                    font-family: Stem-Regular;
                    font-size: 0.875rem;
                    line-height: 1rem;
                    text-align: left;
                    color: var(--white-black);
                }
            }

            .filter-view-title {
                font-family: Stem-Regular;
                font-size: 0.875rem;
                line-height: 1rem;
                color: var(--white-black);
                text-align: left;
            }

            .filter-view {
                padding: 10px 0px;
            }

            .filter-view-text {
                margin-left: 10px;
                margin-top: 10px;
                border-bottom: 1px solid #DDDFE2;
                padding-bottom: 7px;

                p {
                    font-family: Stem-Regular;
                    font-size: 0.875rem;
                    line-height: 1rem;
                    color: var(--white-black);
                }

            }
        }
    }

    .technique {
        padding: 0;
        justify-content: center;

        .technique-item {
            padding: 20px;
        }

        .technique-card {
            width: 320px;
            padding: 10px 20px;

            .technique-text {
                h1 {
                    font-family: Stem-Medium;
                    font-size: 1.125rem;
                    line-height: 1.25rem;
                    color: var(--color-blue);
                    margin-top: 10px;
                }

            }

            .technique-button {

                .technique-button-black {
                    padding: 10px;
                }

                .technique-button-blue {
                    padding: 10px;
                }
            }
        }
    }

}

@media screen and(max-width:360px) {

    .technique {
        width: 100%;

        .technique-item {
            width: 100%;
        }

        .technique-card {
            width: 100%;

        }
    }

}
</style>
